
























import {
  Vue,
  Component,
  Inject,
  ProvideReactive,
  Watch,
} from 'vue-property-decorator';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import { Polygon } from '@/models/entities/Polygon';
import PageHeader1 from '@/components/PageHeader1.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { Route } from 'vue-router';

@Component({
  components: {
    PageHeader1,
  },
})
export default class PolygonView extends Vue {
  @Inject() readonly appStore!: Context<AppModule>;
  @Inject() readonly apiFacade!: ApiFacade;
  @Inject() setCurrentPolygonInSideMenu!: (polygonId: number) => void;
  @ProvideReactive() polygon: Polygon | null = null;

  @Watch('$route')
  async routerWatcher(to: Route) {
    const id = parseInt(to.params.id, 10);

    if (id && id !== this.polygon?.polygonId) {
      this.polygon = await this.apiFacade.fetchPolygonInfo(id);
    }
  }

  async mounted() {
    const id = parseInt(this.$route.params.id, 10);
    if (!id) {
      return;
    }
    this.polygon = await this.apiFacade.fetchPolygonInfo(id);
    await this.$nextTick();
    this.setCurrentPolygonInSideMenu(id);
  }

  get loaded() {
    return !!this.polygon;
  }

  get name() {
    return this.polygon?.name;
  }

  polygonUpdated(updPolygon: Polygon) {
    this.polygon = updPolygon;
  }
}
